
// n is the number of stars required
@function multiple-box-shadow($n) {
  $value: "#{random(2000)}px #{random(2000)}px #FFF";
  @for $i from 2 through $n {
    $value: "#{$value} , #{random(2000)}px #{random(2000)}px #FFF";
  }

  @return unquote($value);
}

$shadows-small: multiple-box-shadow(700);
$shadows-medium: multiple-box-shadow(200);
$shadows-big: multiple-box-shadow(100);

.landing-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url("../../assets/background.jpg");
    background-size: cover;
    background-position: center;
  
    .welcome-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 8px;
  
      h1 {
        margin-bottom: 1rem;
      }
  
      .buttons {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
      
        button {
          width: 150px;
          height: 50px;
          font-size: 1rem;
          border-radius: 0.5rem;
          border: 2px solid;
          background-color: white;
          cursor: pointer;
          transition: background-color 0.3s, color 0.3s;
      
          &:hover {
            color: white;
          }
        }
      
        .enter {
          border-color: red;
      
          &:hover {
            background-color: red;
          }
        }
      
        .exit {
          border-color: blue;
      
          &:hover {
            background-color: blue;
          }
        }
      }
      
    }
  }

  .landing-page {
    position: relative;
    overflow: hidden;
    background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%);
    
    #stars,
    #stars2,
    #stars3 {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  
    #stars {
      width: 1px;
      height: 1px;
      background: transparent;
      box-shadow: $shadows-small;
      animation: animStar 50s linear infinite;
  
      &:after {
        content: " ";
        position: absolute;
        top: 2000px;
        width: 1px;
        height: 1px;
        background: transparent;
        box-shadow: $shadows-small;
      }
    }
  
    #stars2 {
      width: 2px;
      height: 2px;
      background: transparent;
      box-shadow: $shadows-medium;
      animation: animStar 100s linear infinite;
  
      &:after {
        content: " ";
        position: absolute;
        top: 2000px;
        width: 2px;
        height: 2px;
        background: transparent;
        box-shadow: $shadows-medium;
      }
    }
  
    #stars3 {
      width: 3px;
      height: 3px;
      background: transparent;
      box-shadow: $shadows-big;
      animation: animStar 150s linear infinite;
  
      &:after {
        content: " ";
        position: absolute;
        top: 2000px;
        width: 3px;
        height: 3px;
        background: transparent;
        box-shadow: $shadows-big;
      }
    }
  
    #title {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      color: #FFF;
      text-align: center;
      font-family: "lato", sans-serif;
      font-weight: 300;
      font-size: 50px;
      letter-spacing: 10px;
      margin-top: -60px;
      padding-left: 10px;
  
      span {
        background: -webkit-linear-gradient(white, #38495a);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  
    @keyframes animStar {
      from {
        transform: translateY(0px);
      }
      to {
        transform: translateY(-2000px);
      }
    }
  }
  