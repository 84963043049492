.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    padding-top: 4rem;
  }
  
  .text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .text-container h1 {
    margin: 0;
    font-size: 3rem;
    color: rgba(225, 225, 225, .01);
    background-image: url("https://source.unsplash.com/iar-afB0QQw");
    background-repeat: repeat;
    -webkit-background-clip: text;
    animation: animate 15s ease-in-out infinite;
    text-align: center;
    text-transform: uppercase;
    font-weight: 900;
    white-space: nowrap;
  }
  
  @keyframes animate {
    0%, 100% {
      background-position: center top;
    }
    50% {
      background-position: center bottom;
    }
  }
  
  .greeting-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .greeting-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
  
    a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 3.75rem;
      height: 3.75rem;
      transition: transform 0.3s ease;
  
      &:hover {
        transform: scale(1.1);
      }
  
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  
  .paragraph-box {
    margin-top: 2rem;
    max-width: 60rem;
    padding: 1rem;
    border: 1px solid #fff;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.1);
    text-align: justify;
  }
  
  .paragraph-box p {
    margin: 0;
    color: #fff;
  }
  