.projects {
    text-align: center;
    padding: 2rem;
    h2 {
      margin-bottom: 1rem;
    }
    .project-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
    }
  }
  