.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 3rem;
  left: 0;
  right: 0;
  padding: 0.5rem;
  z-index: 1000;
}

.nav-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin: 0 1rem;
  font-size: 1.1rem;
  color: #ffffff;
  cursor: pointer;
  transition: color 0.3s;
}

.nav-item:hover {
  color: #2f373a;
}

.nav-list:hover .nav-item {
  color: #aaaaaa;
}

.nav-list:hover .nav-item:hover {
  color: #2f373a;
}

.active {
  color: #6ec1e4;
}