.contact {
    text-align: center;
    padding: 2rem;
    h2 {
      margin-bottom: 1rem;
    }
    ul {
      list-style-type: none;
      padding: 0;
      li {
        margin-bottom: 0.5rem;
        a {
          text-decoration: none;
          color: #333;
          &:hover {
            color: #007bff;
          }
        }
      }
    }
  }
  